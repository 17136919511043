<template>
    <main class="main-page" id="">
        <section class="page-section q-pa-md" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="">
                            <NiceImage class="img-fluid" src="images/banner-demo-2.jpg"   style="max-width:100%;" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section mb-3" >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="">
                            <div class="">
                                <DashboardListPage ref="dashboardListPage"  :limit="10" :show-header="false" :show-breadcrumbs="true" :show-footer="false" :paginate="false" page-store-key="HOME-DASHBOARD" is-sub-page>
                                </DashboardListPage>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>

</template>
<script setup>
	import {  ref } from 'vue';
	
	
	import { useApp } from 'src/composables/app.js';
	
	
	import DashboardListPage from "../dashboard/list.vue";
	
	const props = defineProps({
		pageName: {
			type: String,
			default: 'home',
		},
		routeName: {
			type: String,
			default: 'home',
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	
	const pageReady = ref(true);
	
</script>
<style scoped>
    .container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
.item {
            display: flex;
            align-items: center;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            transition: background-color 0.3s;
        }
        .item:hover {
            background-color: #f0f0f0;
        }
        .item img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }
        .item span {
            font-size: 16px;
        }
</style>
